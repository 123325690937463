@import url('https://fonts.googleapis.com/css?family=Nunito:900,700,600,400,300');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700');

:root {
    --page-container-bgcolor: #white;
}

body {
    font-family: 'Nunito' !important;
    color: var(--atl-main-text);
}

/*Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 3px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 5px;
}

/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.truncated-text {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.webViewer {
    height: 100vh;
}

/* Give all Font Awesome icons in the app the equivalent of Bootstrap m-1 */
.svg-inline--fa {
    margin: .25rem;
}

.tooltip-purple .tooltip-inner {
    border-radius: 5px;
    background-color: #321E4B;
}

.tooltip-purple .tooltip-arrow {
    color: #321E4B;
}

.tooltip-purple .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {

    border-bottom-color: #321E4B !important;
}