@keyframes grow {
    0% { 
        opacity: 0;
        height: 0%;
        transform: translateY(-5%);
    }
    50% {
        opacity: 0.4; 
        height: 5%;
    }
    100% { 
        opacity: 1; 
        height: 100%;
        transform: translateY(0);
    }
}
  
@keyframes shrink {
    0% { 
        height: 100%;
        transform: translateY(0);
    }
    50% {
        opacity: 0.3; 
        height: 5%;
    }
    100% { 
        opacity: 0; 
        height: 0%;
        transform: translateY(-5%);
    }
}

.collapse-content {
    border-left: 1px solid var(--atl-primary);
    margin-left: 1.3rem; /* bootstrap margins couldn't quite get it lined up exactly */
}

.collapse-md-group-content {
    border-left: 1px solid var(--atl-primary);
    margin-left: 0.9rem;
}

.grow-animation {
    animation-name: grow;
    animation-duration: 0.4s;
    transition-timing-function: ease-out;
    transition: 0.4s;
}

.shrink-animation {
    animation-name: shrink;
    animation-duration: 0.4s;
    transition-timing-function: ease-in;
    transition: 0.4s;
}