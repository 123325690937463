.no-notes-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    padding-top: 25px;
}

.no-notes-mailbox {
    height: 175px;
    width: 200px;
}

.notes-parent {
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
}

.notes-content {
    flex-grow: 1;
    overflow-y: auto;
}

.note-box-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.note-box {
    flex-grow: 1;
    overflow: auto;
    max-height: 175px;
    height: auto;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    border: 1px solid #ced4da;
    border-radius: .375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.note-box:hover {
    border-color: var(--atl-primary);
    box-shadow: 0 0 0 0.10rem rgba(var(--atl-primary-rgb), .10);
}

.note-box:focus {
    border-color: var(--atl-primary);
    box-shadow: 0 0 0 0.15rem rgba(var(--atl-primary-rgb), .15);
    outline: none;
}

.note-box[data-placeholderText]:empty:before {
    content: attr(data-placeholderText);
    color: #696969;
}

.note-submit-button {
    padding: 0.25px;
    margin-left: 4px;
    margin-bottom: 7px;
    height: 24px;
    width: 24px;
}

.note-skeleton-loader {
    display: block;
}

.note-skeleton-loader-left {
    margin-left: 0 !important;
    margin-right: auto !important;
}

.note-skeleton-loader-right {
    margin-left: auto !important;
    margin-right: 0 !important;
}

.note-skeleton-loader-notes {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.note-skeleton-loader-note-box-container {
    display: flex;
    align-items: center;
}

.note-skeleton-loader-note-box {
    flex-grow: 1;
}

.note-skeleton-loader-submit-btn {
    flex-shrink: 0;
    margin-left: 8px;
    width: 20px;
}

.note-card-self {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.note-card-other {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.note-text {
    width: 80%;
    background-color: #F8F8F8;
    border: none;
}

.note-card-self>.note-text {
    width: 80%;
    background-color: #F8F3FC;
}

.note-text.editing {
    border-radius: 5px;
    border: 1px solid var(--CTA-Purple-Enabled, #7514BD);
    background: var(--White, #FFF);

    /* Drop Shadow style */
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.20);
}

.note-box.editing {
    background-color: white;
    border: none;

    box-shadow: none;

    white-space: pre-wrap;
}

.display-note-text {
    white-space: pre-wrap;
}