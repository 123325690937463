.details-cabinet-size {
    height: 100px;
    color: #8E8E8E;
    font-size: 100px;
}

.details-drawer-size {
    height: 100px;
    color: #8E8E8E;
    font-size: 125px;
}

.details-calculate-link {
    text-align: center;
    margin: 15px 0 0 0;
}