.cursor-auto {
    cursor: auto;
}

.sideNav-toggler {
    color: white;
    font-size: 0.875rem;
    line-height: 1.3rem;
    font-weight: 400;
    margin: 1.35rem;
}

.sideNav-header {
    height: 4rem;
}

@keyframes displayAndFadeIn {
    0% {
        display: none;
        opacity: 0;
    }

    100% {
        display: initial;
        opacity: 1;
    }
}

@keyframes fadeOutToDisplayNone {
    0% {
        display: initial;
        opacity: 1;
    }

    100% {
        opacity: 0;
        display: none;
    }
}

.sideNav-icon {
    height: 55px;
    margin-top: 6px;
    animation: var(--animation-duration) 1 forwards displayAndFadeIn;
    -webkit-animation: var(--animation-duration) 1 forwards displayAndFadeIn;
    -moz-animation: var(--animation-duration) 1 forwards displayAndFadeIn;
    -o-animation: var(--animation-duration) 1 forwards displayAndFadeIn;
}

.sideNav-icon.collapsed {
    animation: var(--animation-duration) 1 forwards fadeOutToDisplayNone;
    -webkit-animation: var(--animation-duration) 1 forwards fadeOutToDisplayNone;
    -moz-animation: var(--animation-duration) 1 forwards fadeOutToDisplayNone;
    -o-animation: var(--animation-duration) 1 forwards fadeOutToDisplayNone;
}

.sideNav-icon-new {
    height: 35px;
    margin-top: 0.825rem;
    animation: var(--animation-duration) 1 forwards displayAndFadeIn;
    -webkit-animation: var(--animation-duration) 1 forwards displayAndFadeIn;
    -moz-animation: var(--animation-duration) 1 forwards displayAndFadeIn;
    -o-animation: var(--animation-duration) 1 forwards displayAndFadeIn;
}

.sideNav-icon-new.collapsed {
    animation: var(--animation-duration) 1 forwards fadeOutToDisplayNone;
    -webkit-animation: var(--animation-duration) 1 forwards fadeOutToDisplayNone;
    -moz-animation: var(--animation-duration) 1 forwards fadeOutToDisplayNone;
    -o-animation: var(--animation-duration) 1 forwards fadeOutToDisplayNone;
}

@media screen and (max-width: 768px) {
    .sideNav-icon {
        animation: var(--animation-duration) 1 forwards fadeOutToDisplayNone;
        -webkit-animation: var(--animation-duration) 1 forwards fadeOutToDisplayNone;
        -moz-animation: var(--animation-duration) 1 forwards fadeOutToDisplayNone;
        -o-animation: var(--animation-duration) 1 forwards fadeOutToDisplayNone;
    }

    .sideNav-icon-new {
        animation: var(--animation-duration) 1 forwards fadeOutToDisplayNone;
        -webkit-animation: var(--animation-duration) 1 forwards fadeOutToDisplayNone;
        -moz-animation: var(--animation-duration) 1 forwards fadeOutToDisplayNone;
        -o-animation: var(--animation-duration) 1 forwards fadeOutToDisplayNone;
    }
}
