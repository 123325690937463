.linked-view-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 68px;
    padding: 0 1rem;
    background-color: #7514BD !important;
}

.linked-view-revver-logo {
    height: 40px;
    margin-left: .5rem;
    margin-right: 1.5rem;
}

.launch-revver-spacer {
    margin: 1rem;
}