.help-navmenu {
    width: 20rem;
    cursor: default;
    border: 1px solid rgb(236, 236, 236);
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 15%) 0px 4px 15px;
    font-size: 14px;
}

.feedback-input {
    resize: none;
    min-height: 160px !important;
}

.success-feedback-input {
    resize: none;
    min-height: 72px !important;
}

.feedback-input-close {
    font-size: .875rem;
}

.previewerx-feedback-slider-option {
    margin-left: 23px;
}

.feedback-toast-wrapper {
    position: fixed;
    background-color: #fff;
    bottom: 20px;
    right: 40px;
    width: 400px;
}

.feedback-toast-header {
    padding: 0.5rem 1.5rem;
    padding-top: 1rem;
    border-bottom: none;
}

.feedback-toast-body {
    padding: 0.75rem 1.5rem;
}

.feedback-toast-footer {
    float: right;
    padding-bottom: 1rem;
}

.feedback-toast-submit-button {
    margin-left: 13px;
}