.notifications-navmenu {
    max-height: 70vh;
    overflow-y: auto;
    width: 375px;
    overflow-x: hidden;
    cursor: default;
    border: 1px solid rgb(236, 236, 236);
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 15%) 0px 4px 15px;
    font-size: 14px;
}

.notifications-navmenu-mobile {
    width: 100vw;
    height: 100vh;
    cursor: default;
    border: 1px solid rgb(236, 236, 236);
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 15%) 0px 4px 15px;
    font-size: 14px;
    position: absolute !important;
    display: none;
    top: -50px !important;
    left: 0px !important;
}

.notifications-navmenu-div {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
}

.empty-notifications-navmenu-text {
    margin: 20px 0px !important;
    justify-content: center;
    text-align: center;
}

.exit-button-mobile-notifications {
    float: right;
}
