/* FileUploadNavMenuIcon */

.nav-menu-icon {
    margin: 2px 0px 0px 0px;
}

.nav-menu-icon-with-progress-bar {
    margin: 6px 0px 0px 0px;
}

.nav-menu-icon-progress-bar {
    height: 4px;
    margin: 0px 7px;
}

.nav-menu-icon-progress-bar > div {
    background-color: #34C659;
}

.failed-uploads-bubble {
    color: #CB411E;
    position: absolute;
    right: -5px;
    top: -3px;
}

/* FileUploadNavMenuDropdown */

.nav-menu-dropdown {
    cursor: default;
    border: 1px solid rgb(236, 236, 236);
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 15%) 0px 4px 15px;
    font-size: 14px;
    height: 430px;
    padding: 0px;
    width: 375px;
}

.nav-menu-dropdown-card-body {
    padding-bottom: 12px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 430px;
}

.with-failed-uploads {
    height: 385px;
}

.nav-menu-dropdown-card-body > .infinite-scroll-component__outerdiv > .infinite-scroll-component {
    overflow: inherit !important;
}

.clear-all-uploads-button {
    justify-content: center;
    display: flex;
    padding: 0px;
}

.clear-all-uploads-button-text {
    padding: 12px;
    cursor: pointer;
    color: #B4B4B4;
}

/* FileUploadNavMenuSessionUploads */

.session-upload-item {
    line-height: 24px;
    align-items: center;
}

.session-upload-item-success-icon {
    color: #34C659;
}

.session-upload-item-processing {
    padding: 0px 12px 0px 12px;
}

.session-upload-item-processing-progress-bar {
    height: 10px;
    margin-top: 7px;
}

.session-upload-item-processing-progress-bar > div {
    background-color: #34C659;
}

/* FileUploadNavMenuFailedUploads */

.failed-upload-item {
    line-height: 24px;
    color: var(--atl-danger);
    align-items: center;
}

.failed-upload-item-delete-icon {
    cursor: pointer;
    color: #696969;
}

.new-file-upload-tooltip {
    --bs-tooltip-padding-x: 15px;
    --bs-tooltip-padding-y: 20px;
}