.item-row-container-selectable {
    height: auto;
    min-height: 35px;
}

.item-row-container-selectable:hover {
    background: #F8F3FC;
    cursor: pointer;
}

.item-row-container-unselectable {
    height: auto;
    min-height: 35px;
}

.item-row {
    height: auto;
    min-height: 35px;
}

.item-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.item-text-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 5px;
    color: #292929 !important;
}