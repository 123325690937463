.no-underline {
    text-decoration: none;
}

.truncate-todo-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.no-wrap {
    white-space: nowrap;
}

.todo-zero-state-link-bold {
    font-weight: bolder;
}