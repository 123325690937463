.sidesheet-tab {
    display: flex;
    height: 56px;
    width: 40px;
    border: none !important;
    border-bottom: 1px solid #ececec !important;
    padding: 0;
}

.tab-button {
    border-radius: 75px;
    width: 2.5em;
    height: 2.5em;
    padding: .125em !important;
    margin-left:auto;
    margin-right:auto;
    margin-top:auto;
    margin-bottom:auto;
}

.tab-card {
    padding-left: .7rem;
    padding-right: .7rem;
}

.tab-no-border {
    border: none;
}

.tab-hide {
    display: none;
}

.tab-column {
    max-width: 2.5rem;
    font-size: .9rem;
    border-right: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
}

.tab-icon {
    color: #696969;
}

.tab-icon.active {
    color: var(--atl-primary);
}

.sidesheet-tab-title {
    vertical-align: middle;
}

.sidesheet-tab-title > h5 {
    margin-bottom: 0px;
}

.sidesheet-with-secondary-button-alternate-padding {
    padding-top: 8px;
}

.sidesheet-secondary-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-left: 16px;
    margin-right: 16px;
}

.sidesheet-content-full-width {
    padding-left: 0px;
    padding-right: 0px;
}

.sidesheet-content-full-width-title-only {
    padding-left: var(--bs-card-spacer-x);
    padding-right: var(--bs-card-spacer-x);
}

.sidesheet-content-full-width-title-only > button {
    padding-right: 10px;
}

.sidesheet-resizer-slider {
    width: 4px;
    cursor: ew-resize;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    padding-left: 0;
}

.sidesheet-pane-col {
    overflow: auto;
}

.sidesheet-close-button {
    height: 5px;
    width: 5px;
}