.md-group-mobile-modal-spacing {
    margin-top: calc(100vh/2 - 16rem);
}

.md-group-modal-name-size {
    border-color: #B4B4B4;
    max-width: 440px;
}

.fields-to-add-col {
    margin-top: 1.5rem;
    overflow-y: auto;
}

.revver-library-col {
    margin-top: 1.5rem;
    overflow-y: auto;
}

.xxl-group-modal {
    overflow: hidden;
    height: 100vh;
    width: 80vw;
    margin-top: 0px;
    margin-bottom: 0px;
    --bs-modal-width: 1400px;
}

.xxl-group-modal-header
{
    height: var(--nav-height);
}

.xxl-group-modal-header-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 1rem;
    margin-bottom: 0;
    padding-left: 0.25rem;
}

.xxl-group-modal-body {
    height: calc(100vh - var(--nav-height)* 3);
    overflow: hidden;
    background-color: #F6F6F6;
}

.xxl-group-modal-body-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;
}

.xxl-group-modal-body-card {
    background-color: white;
    padding: 10px 30px;
}

.xxl-group-modal-footer {
    height: var(--nav-height);
    justify-content: flex-end;
    padding-right: 50px;
    padding-top: 10px;
    box-shadow: 0px -5px 15px 0px #00000026;
    z-index: 10;
}

.xxl-group-modal-divider {
    padding: 0;
    background-color: #ECECEC;
    width: 2px;
    margin-left: 5px;
}

.xxl-group-modal-col {
    padding-bottom: calc(var(--nav-height) + 2.5rem);
}

.xxl-group-modal-col-scroll {
    overflow-y: auto;
    overflow-x: hidden;
}