body {
    color: #434343;
    /*background: #f6f4f2;*/
}

.login-card {
    border-radius: 30px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.10);
    min-height: 625px;
    width: 450px;
}

.revver-icon {
    width: 92.5%;
    height: 65px;
}

.status {
    width: 50%;
}

.login-spinner {
    width: 80px;
    height: 80px;
}

/* Responsive layout */
@media (max-width: 576px) {
    body {
        background-color: #fff;
    }

    .content-wrapper {
        justify-content: start !important;
    }

    .login-card {
        border: none !important;
        border-radius: 0px !important;
        width: auto !important;
        min-height: auto !important;
        box-shadow: none !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

}