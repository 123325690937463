.field-border {
    border-radius: 5px;
    touch-action: none;
    border: 1px solid #ECECEC;
    padding: 10px 5px 5px 5px;
    margin: 5px 0px;
}

.field-border:hover {
    border-radius: 5px;
    border: 1px solid var(--grey-dark-10, #ECECEC);
    background: var(--CTA-Purple-Light-Hover, #F8F3FC);

    /* Drop Shadow style */
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.20);
}

.remove-field-from-group {
    visibility: hidden;
}

.field-border:hover .remove-field-from-group {
    visibility: visible;
}