.account-tile {
    background: #F8F8F8;
    border-radius: 5px;
}

.badge-account-tile {
    border-radius: 0;
    font-size: 22px;
    line-height: 1;
    padding: .375rem .5625rem;
    font-weight: bold;
}

.badge-pill-round-tile {
    border-radius: 10rem;
}
