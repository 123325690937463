.md-sidesheet-container {
    padding: 0px;
}

.md-sidesheet-title {
    border-bottom: #ECECEC 1px solid;
    
}

.md-sidesheet-col-scroll {
    overflow-y: auto;
    overflow-x: hidden;
}