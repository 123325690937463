.group-icon {
    margin: 0 auto;
    color: #696969;
}

.role-type-custom-icon {
    height: 1em;
}

.group-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #696969 !important;
}

.group-row-container { }

.group-row-container :hover {
    background: #F8F3FC;
    cursor: pointer;
}

.group-row {
    height: 40px;
}

.group-text-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 5px;
}

.group-dropdown-icon {
    margin-left: .75rem !important;
    color: #696969;
}