.all-features-slideout-spacing {
    margin-bottom: 0.03em;
    height: calc(100vh - 4.1rem) !important;
    border-radius: 3em 3em 0 0;
    background-color: #321e4b !important;
    z-index: 0 !important;
}

.all-features-slideout-header {
    font-family: Nunito;
    font-size: 1.25em;
    font-weight: 700;
    line-height: 1.7em;
    letter-spacing: 0em;
    text-align: left;
    margin: 0.5em 0 0 2em;
    padding: 0.5em 0 0.2em 0;
    color: white;
}

.all-features-slideout-search-input {
    margin-bottom: 1em;
}
