.md-sidesheet-tab {
    display: flex;
    height: 56px;
    width: 40px;
    border: none !important;
    padding: 60px 0px 0px 0px;
}

.tab-button {
    border-radius: 75px;
    width: 2.5em;
    height: 2.5em;
    padding: .125em !important;
    margin-left:auto;
    margin-right:auto;
    margin-top:auto;
    margin-bottom:auto;
}

.md-tab-icon {
    color: var(--atl-primary);
}

.sidesheet-tab-title {
    vertical-align: middle;
}

.sidesheet-tab-title > h5 {
    margin-bottom: 0px;
}

.sidesheet-with-secondary-button-alternate-padding {
    padding-top: 8px;
}

.sidesheet-secondary-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-left: 16px;
    margin-right: 16px;
}

.sidesheet-content-full-width {
    padding-left: 0px;
    padding-right: 0px;
}

.sidesheet-content-full-width-title-only {
    padding-left: var(--bs-card-spacer-x);
    padding-right: var(--bs-card-spacer-x);
}

.sidesheet-content-full-width-title-only > button {
    padding-right: 10px;
}

.sidesheet-resizer-slider {
    width: 4px;
    cursor: ew-resize;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    padding-left: 0;
}

.sidesheet-pane-col {
    overflow: auto;
}

.metadata-column-divider {
    padding: 0;
    background-color: #ECECEC;
    width: 1px !important;
    margin-left: 5px;
    margin-top: 50px;
    flex: 0 0 auto;
}

.metadata-tab-hide {
    display: none;
}

.md-sidesheet-title-divider {
    background-color: #ECECEC;
    height: 1px;
}