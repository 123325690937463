
.help-navmenu-card {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
}

.need-help-tile {
    background: #F8F8F8;
    border: 1px solid #7514BD;
    border-radius: 5px;
}