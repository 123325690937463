.option-item {
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    border: 1px solid #B4B4B4;
}

.option-item:hover {
    background-color: #F8F3FC;
}

.empty-span {
    width: 22px;
}

.make-default {
    color: grey;
    border: 1px solid grey;
    border-radius: 5px;
}

.default-star {
    margin-right: 6px;
}