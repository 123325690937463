.feedback-container {
    display: flex;
    width: 103px;
    height: 40px;
    top: 122px;
    left: 251px;
    padding: 5px 2px 0px 4px;
    gap: 3px;
    border-radius: 5px;
    border: 2px solid #7514BD
}

.feedback-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.feedback-icon:focus {
    outline: none;
}

.icon-default {
    color: #292929;
}

.icon-selected {
    color: #410370;
}

.icon-not-selected {
    color: #B4B4B4;
}