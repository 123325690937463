.sidesheet-onboarding-modal-sm {
    background-image: url('../../../assets/images/sidesheetOnboardingModalSm.svg');
}

.sidesheet-onboarding-modal-wrapper {
    width: 361px;
}

.sidesheet-onboarding-modal-sm-body {
    height: 308px;
}