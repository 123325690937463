.pointer {
    cursor: pointer;
}

.checklist-tooltip * {
    text-align: left !important;
}

.checklist-tooltip .tooltip-inner {
    background-color: #321E4B !important;
    padding: 20px 15px !important;
}