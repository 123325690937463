.usage-counts {
    position: absolute;
    top: 10px;
    right: 20px;
}

.usage-spacing {
    vertical-align: super;
    margin: 0 5px;
}

.usage-info-tooltip .tooltip-inner {
    background-color: #321E4B !important;
    padding: 20px 15px !important;
    max-width: 330px;
    text-align: justify;
}

.usage-info-tooltip .tooltip {
    opacity: 1 !important;
}

.usage-info-list-tooltip {
    margin: 10px 0px;
}
