.delete-permission-icon {
    visibility: hidden;
}

.permission-details-table-row {
    vertical-align: middle;
}

.permission-details-table-row:hover {
    background-color: #F8F3FC;
}

.permission-details-table-row:hover .delete-permission-icon {
    visibility: visible;
    cursor: pointer;
}

.lower-permission-checkbox {
    background-color: '#B4B4B4';
    border-color: '#B4B4B4';
}

.permission-role-custom-icon {
    height: 1em;
}

.pointer {
    cursor: pointer !important;
}

.pointer:disabled:not(:checked) {
    background-color: #ECECEC;
}