.md-field-mobile-modal-spacing {
    margin-top: calc(100vh/2 - 16rem);
}

.md-field-modal-field-size {
    border-color: #B4B4B4;
    max-width: 440px;
}

.md-field-modal-dropdown-text {
    border: 1px solid #B4B4B4;
    border-radius: 5px 0 0 5px;
    width: 400px;
    height: 40px;
    padding: 10px 5px;
}

.md-field-modal-dropdown-caret {
    border: 1px solid #B4B4B4;
    border-radius: 0 5px 5px 0;
    border-left: initial;
    width: 40px;
    height: 40px;
    padding: 10px;
}

.md-field-modal-type-dropdown-text-disabled {
    border-radius: 5px;
    max-width: 440px;
    background-color: #d3d3d33b;
}