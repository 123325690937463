.role-chip-wrapper {
    height: 35px;
    display: block;
    background-color: #F8F3FC;
    border: 1px solid #7514BD;
    border-radius: 5px;
    margin: 2px;
    padding: 2px 8px 2px 2px;
}

.role-chip-icon {
    color: #292929 !important;
    margin: .25rem;
    position: relative;
    top: calc(50% - 10px);
}

.role-chip-guest-icon {
    top: calc(50% - 12px);
}

.role-chip-close-icon {
    width: 14px;
    margin-left: .3rem !important;
    margin-right: 0px !important;
    cursor: pointer;
}

.role-chip-text-wrapper {
    display: inline;
    align-items: center;
    height: 100%;
    margin-left: 5px;
    color: #292929 !important;
}