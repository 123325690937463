@media screen and (max-width: 575px) {
    .docRow {
        width: 100%;
        height: 100%;
    }
}

@media screen and (min-width: 576px) {
    .docRow {
        width: 100%;
        height: calc(100% - 1rem);
    }
}

.overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.01);
    width: 100%;
    height: 100%;
    z-index: 2;
}

.utopia-iframe-border-overlay {
    overflow: hidden;
}

.documentsIframeContainer {
    position: relative;
}

.sde-btn {
    position: absolute;
    top: 10px;
    right: 40px;
    z-index: 2;
}