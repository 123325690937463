.accounts-navmenu {
    cursor: default;
    border: 1px solid rgb(236, 236, 236);
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 15%) 0px 4px 15px;
    font-size: 14px;
}

.accounts-navmenu-full-menu {
    width: 20rem;
}

.accounts-navmenu-mobile {
    width: 100vw;
    height: 100vh;
    cursor: default;
    border: 1px solid rgb(236, 236, 236);
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 15%) 0px 4px 15px;
    font-size: 14px;
    position: absolute !important;
    display: none;
    top: -50px !important;
    left: 0px !important;
}

.account-tile {
    background: #f8f8f8;
    border-radius: 5px;
}

.exit-button-mobile-acccount {
    float: right;
}