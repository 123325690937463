.output-container {
    margin-left: '4px';
}

pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    height: auto;
    max-height: none;
    overflow: visible;
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
}
code {
    font-family: monospace;
    color: #333;
}
