.new-experience-mobile-modal-spacing {
    margin-top: calc(100vh/2 - 16rem);
}

.close-button {
    font-size: .875rem;
}

.admin-welcome-modal-header > h5 {
    font-size: 28px !important;
}