.IconShadow {
    border-radius: 3px;
    height: 24px;
    width: 24px;
}

.IconShadow:hover {
    background: var(--CTA-Purple-Light-Hover, #F1E9F8);
}

.IconShadow:focus {
    background: var(--CTA-Purple-Light-Focused, #F1E9F8);
}

.IconShadow:active {
    background: var(--CTA-Purple-Light-Active, #E5D6F0);
}

.icon {
    border-radius: 3px;
}

.icon:hover {
    color: #500886;
}

.icon:focus {
    background: var(--CTA-Purple-Light-Focused, #F1E9F8);
}

.icon:active {
    background: var(--CTA-Purple-Light-Active, #E5D6F0);
}