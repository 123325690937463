.sign-in-preview-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(128, 128, 128, 0.5);
}

.sign-in-preview-container {
    position: relative;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    min-width: 280px;
}

.sign-in-preview-container * {
    cursor: default;
}

.sign-in-form {
    padding: 2.5rem 2rem;
    border-radius: 30px;
    background-color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 539px;
    width: 380px;
    min-width: 270px;
    max-width: 380px;
}

.sign-in-form .account-name {
    font-size: .75rem;
    font-weight: 700;
    text-align: center;
    width: 100%;
}

.logo-container {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.sign-in-preview-container .logo {
    background-color: #D9D9D9;
    width: 70%;
}

.sign-in-form .logo-placeholder {
    width: 256px;
}

.revver-purple {
    color: #7514bd;
}

.username {
    width: 100%;
    margin-top: 1rem;
}

.username > .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.username > .label .checkbox-container {
    display: flex;
    align-items: center;
}

.username > .label .checkbox {
    height: 12px !important;
    width: 12px !important;
    border: 1px solid rgb(68, 68, 68);
    border-radius: 2px;
    margin-bottom: 1px;
    margin-right: 3px;
}

.username > .input-container {
    height: 45px;
    border: 1px solid #bababa;
    border-radius: 5px;
    margin-top: .5rem;
}

.sign-in-form > .button {
    width: 100%;
    height: 40px;
    border: 1px solid #7514bd;
    border-radius: 5px;
    background-color: #7514bd;
    color: #fff;
    text-align: center;
    padding: 0.375rem 0.75rem;
    margin-top: 2rem;
    margin-bottom: 4rem;
}