.list-selector-input {
    border-radius: .375rem 0 0 .375rem;
    padding: .375rem .75rem .47rem .75rem;
    border: 1px solid #B4B4B4;
    font-size: 14px;
    height: 36px;
}

.list-selector-not-input {
    display: flex;
    justify-content: center;
    border-radius: .375rem 0 0 .375rem;
    padding: .375rem .75rem .47rem .75rem;
    border: 1px solid #B4B4B4;
    font-size: 14px;
    height: 36px;
    width: 100%;
    color: #292929 !important;
}

.list-selector-button {
    border-radius: 0 .375rem .375rem 0;
    border: 1px solid #B4B4B4;
    color: #292929;
    padding: .27rem .19rem;
    height: 36px;
}

.list-selector-results-container {
    overflow-y: auto;
    overflow-x: hidden;
    height: 200px;
    width: 100%;
    background: white;
    border: 1px solid #ECECEC;
    border-radius: .375rem;
    box-shadow: 0px 5px 10px #ECECEC;
    padding: 10px;
}

.list-selector-container {
    position: relative;
    display: grid;
}

.list-selector-container-row {
    grid-row-start: 1;
    grid-column-start: 1;
    z-index: 1;
}

.list-selector-chip-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.list-selector-search-row {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
}

.mb-20 {
    margin-bottom: 20px;
}

.z-100 {
    z-index: 100;
}

.button-area {
    width: 24px;
}

.input-area {
    flex-grow: 20;
}

.column-width {
    max-width: 25px;
}