.modal-body-scrollable {
    max-height: 300px;
    overflow-y: auto;
}

.warning {
    width: 385px;
}

@media (max-width: 375) {
    .warning {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .warning {
        width: 102%;
    }
}

@media (max-width: 575px) {
    .warning {
        width: 104%;
    }
}