.bottom-nav-bar {
    height: var(--nav-height);
    transition: var(--animation-duration);
    background-color: #321E4B !important;
    box-shadow: rgb(0 0 0 / 50%) 0px 4px 15px;
    position: fixed;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: stretch;
    bottom: 0;
    width: 100%;
}

.bottom-nav-list {
    justify-content: center;
    padding-bottom: 1.25em;
}

.bottom-nav-link {
    height: 73px;
    margin-top: 1rem !important;
}

.bottom-nav-icon {
    color: 'white';
}