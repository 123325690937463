.enter-to-submit {
    background-color: #F8F3FC;
}


.button-row {
    display: flex;
    margin: 5px;
    justify-content: space-between;
}

.enter-to-submit:hover {
    background-color: #F1E9F8 !important;
}

.input-row {
    border: 1px solid lightgrey;
    border-radius: 7px;
}

#option-input-field-text {
    border: none;
    box-shadow: none;
}

.option-drop-area {
    height: 325px;
    border: 1px solid lightgrey;
    border-radius: 7px;
    overflow-y: scroll;
}

.empty-area {
    height: 325px;
}

.droparea-container {
    position: relative;
    height: 375px;
}

.droparea-input {
    z-index: 1;
    position: absolute;
    height: 325px;
}

.droparea-button {
    z-index: 2;
    position: absolute;
    left: 82%;
    top: 275px;
}

@media screen and (max-width: 992px) {
    .droparea-button {
        z-index: 2;
        position: absolute;
        left: 68%;
        top: 275px;
    }
}