#file-version-table tbody tr:hover {
    background-color: #F8F3FC;
}

#file-version-table td {
    vertical-align: middle;
}

#file-version-table tbody tr td:last-child {
    text-align: right;
}

#file-version-table tbody tr td:first-child {
    width: 20px;
}

.underline-hover:hover {
    text-decoration: underline;
    text-decoration-color: var(--atl-primary);
}

.light-hover:hover {
    color: var(--atl-primary-dark-hover) !important;
    background-color: rgba(80, 8, 134, 0.05) !important;
}

.file-version-modal {
    max-height: 500px;
    overflow-y: auto;
}

.file-version-modal::-webkit-scrollbar {
    width: 8px;
}

.file-version-modal::-webkit-scrollbar-track {
    background: transparent;
}

.file-version-modal::-webkit-scrollbar-thumb {
    background: #D9D9D9;
}

.file-version-modal::-webkit-scrollbar-thumb:hover {
    background: #bbbbbb;
}

.file-version-comment-bubble {
    height: 2rem;
    width: 24px;
    display: flex;
    align-items: center;
    margin-left: 2px;
    margin-right: 2px;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.file-version-table-note {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media screen and (max-width: 991px) {
    .file-version-table-note {
        max-width: 20px;
    }
}

@media screen and (min-width: 992px) {
    .file-version-table-note {
        max-width: 275px;
    }
}

.file-version-full-note-title h5 {
    font-weight: 700;
}

.file-version-full-note-container {
    margin-top: 8px;
    margin-bottom: 12px;
    padding: 16px;
    border-radius: 5px;
    border: 1px solid #F6F6F6;
    background: #F6F6F6;
    max-height: 150px;
    overflow-y: auto;
}

.file-version-table-tooltip .tooltip {
    opacity: 1;
}

.file-version-table-tooltip .tooltip-inner {
    max-width: 332px;
    background-color: #321E4B;
    padding: 20px 15px;
    text-align: left;
}

.file-version-table-tooltip .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
    border-bottom-color: #321E4B;
}

.file-version-empty-space-not-primary {
    margin-right: 1.1rem;
}