.cabinet-default-node-icon {
    color: #8E8E8E;
    font-size: medium;
    margin-top: -9px;
    height: 0px;
}

.drawer-default-node-icon {
    color: #8E8E8E;
    font-size: 21px;
    margin-top: -10px;
    height: 0px;
}

.cabinet-dark-node-icon {
    color: #292929;
    font-size: medium;
    margin-top: -9px;
    height: 0px;
}

.drawer-dark-node-icon {
    color: #292929;
    font-size: 21px;
    margin-top: -10px;
    height: 0px;
}