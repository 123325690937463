.full-screen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent black */
    z-index: 2000; /* Ensure it's on top of other content */
    pointer-events: none; /* Prevent interactions */
    display: flex;
    align-items: center;
    justify-content: center;
}