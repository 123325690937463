.md-field-modal-checkbox {
    height: 16px;
    width: 16px;
}

.md-field-modal-address-format-large {
    border-color: #B4B4B4;
    height: 40px;
    width: 410px;
}

.md-field-modal-address-format-disabled {
    opacity: 50%;
    background-color: lightgrey;
}

.md-field-modal-address-format-small {
    border-color: #B4B4B4;
    height: 40px;
    width: 180px;
}