body {
    --nav-height: 4rem;
}

.atl-navbar-xs {
    height: var(--nav-height);
    transition: var(--animation-duration);
    background-color: #321E4B !important;
}

.atl-navbar {
    --navbar-left: var(--openVerticalNavWidth);
    position: absolute;
    height: var(--nav-height);
    top: 0;
    left: var(--navbar-left);
    width: calc(100% - var(--navbar-left));
    transition: var(--animation-duration);
}

.atl-navbar.sideNav-collapsed {
    --navbar-left: 3.8rem;
}

.nav-link{
    cursor:pointer;
}

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

.box-shadow {
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.search-box {
    max-width: 400px;
}

.recents-header-icon-menu {
    height: 50vh; 
    width: 500px;
}

.recents-header-icon-div {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
}

.feedback-datalist {
    display: flex;
    justify-content: space-between;
    color: #696969;
    width: 100%;
    position: relative;
}

.feedback-datalist > .shift {
    position: relative;
    right: 17px;
}

.feedbackModal {
    width: 100%;
}

.previewerXFeedbackModal {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateX(-7%) !important;
}

.show-welcome-again-checkbox {
    bottom: -63px;
    width: fit-content;
    float: left;
    position: absolute;
}

.show-welcome-again-checkbox > input {
    cursor: pointer;
}

.show-welcome-again-checkbox > label {
    cursor: pointer;
}

@keyframes displayAndFadeIn {
    0% {
        opacity: 0;
        width: 0;
    }

    100% {
        opacity: 1;
        width: unset;
    }
}

@keyframes fadeOutToDisplayNone {
    0% {
        opacity: 1;
        width: unset;
    }

    100% {
        opacity: 0;
        width: 0;
    }
}

.icon-link {
    height: 40px;
    margin-left: .5rem;
    margin-right: 1.5rem;
    display: none;
    animation: var(--animation-duration) 1 forwards fadeOutToDisplayNone;
    -webkit-animation: var(--animation-duration) 1 forwards fadeOutToDisplayNone;
    -moz-animation: var(--animation-duration) 1 forwards fadeOutToDisplayNone;
    -o-animation: var(--animation-duration) 1 forwards fadeOutToDisplayNone;
}

    .icon-link.sideNav-collapsed {
        display: block;
        animation: var(--animation-duration) 1 forwards displayAndFadeIn;
        -webkit-animation: var(--animation-duration) 1 forwards displayAndFadeIn;
        -moz-animation: var(--animation-duration) 1 forwards displayAndFadeIn;
        -o-animation: var(--animation-duration) 1 forwards displayAndFadeIn;
    }

    .icon-link.old {
        margin-left: .25rem;
        margin-right: 1.5rem;
        margin-top: .25rem;
    }

@media screen and (max-width: 768px) {
    .icon-link {
        display: block;
        animation: var(--animation-duration) 1 forwards displayAndFadeIn;
        -webkit-animation: var(--animation-duration) 1 forwards displayAndFadeIn;
        -moz-animation: var(--animation-duration) 1 forwards displayAndFadeIn;
        -o-animation: var(--animation-duration) 1 forwards displayAndFadeIn;
    }
        
}

@media screen and (max-width: 392px) {
    .icon-link {
        height: 35px;
    }
}

@media screen and (max-width: 364px) {
    .icon-link {
        margin-left: 0rem;
        margin-right: 0rem;
    }
}

@media screen and (max-width: 340px) {
    .icon-link {
        height: 30px;
    }
}

.mobile-remove-spacing {
    padding: 0;
    margin: 0;
}