.full-screen-previewer-modal {
    min-width: 420px;
}

.full-screen-previewer-header {
    height: auto;
}

.full-screen-previewer-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 1rem;
    margin-bottom: 0;
    padding-left: 0.25rem;
}

.full-screen-previewer-version-label{
    margin: 0;
    font-size: 14px;
}

.full-screen-previewer-versions-selector{
    min-width: 260px;
}

.full-screen-previewer-col {
    padding-right: 14px !important;
}