.docked-bar-bar {
    background: #fff;
    width: 100%;
}

.docked-bar-docked .docked-bar-bar {
    position: absolute;
    box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.15);
    border: none !important;
}

.docked-bar-docked.docked-bar-top .docked-bar-bar {
    top: 0;
}

.docked-bar-docked.docked-bar-bottom .docked-bar-bar {
    bottom: 0;
}
