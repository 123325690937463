.cropper-container {
    position: relative;
    height: 285px;
}

.crop-area {
    border-radius: 10px;
    border: 2px dashed #7514bd !important;
}

.crop-container {
    border-radius: 10px;
    border: none;
    background-color: #D9D9D9;
}

.zoom-range {
    width: 117px;
    position: absolute;
    bottom: 10px;
    left: 40%;
}

.logo-preview {
    border-radius: 5px;
    background-color: #D9D9D9;
}

.logo-preview-container {
    width: fit-content;
    position: relative;
}

.adjust-size {
    width: fit-content;
}

.remove-icon-container {
    position: absolute;
    right: -18px;
    top: -10px;
    cursor: pointer;
}

.stack-container {
    position: relative;
}

.remove-icon {
    color: #808080ce;
    position: absolute;
    left: -3px;
    top: 2px;
}

.file-input-area {
    width: 98%;
    height: 280px;
    border: 2px dashed #6969697f;
    border-radius: 10px;
}

.file-input-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.file-icon {
    width: 50.5px;
    height: 67.35px;
}

.logo-file-status {
    width: 740px;
}

.branding-image-modal > .modal-content {
    padding-left: 11.5px;
}

/* page-specific adjustments to docked bar */
.branding-docked-bar.docked-bar-docked {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
}
.branding-docked-bar.docked-bar-docked .docked-bar-bar {
    padding-left: 2.5rem !important;
    padding-right: 3.5rem !important;
}

.help-text {
    max-width: 600px;
}

.cname-container {
    position: relative;
}

.cname-feedback {
    position: absolute;
    top: 2.5rem;
    left: 3.2rem;
}

.character-counter {
    display: flex;
    justify-content: flex-end;
}

.character-counter.invalid {
    color: #CC1531 !important;
}

.character-counter-feedback {
    margin-top: -18px;
}

.branding-page-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(128, 128, 128, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 575px) {
    .docked-bar-bar {
        width: 105%;
    }
}

@media (max-width: 375px) {
    .logo-preview-container, .logo-preview-container img {
        width: 100% !important;
    }
}