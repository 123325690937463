.collapse-icon {
    transition: all 0.2s linear;
}

.collapse-icon.collapse-icon-right {
    transform: rotate(0deg);
}

.collapse-icon.collapse-icon-down {
    transform: rotate(90deg);
}