.recents-navmenu {
    height: 50vh;
    width: 375px;
    cursor: default;
    border: 1px solid rgb(236, 236, 236);
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 15%) 0px 4px 15px;
    font-size: 14px;
}

.recents-navmenu-div {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
}