@charset "UTF-8";

@font-face {
    font-family: 'untitled-font-1';
    src: url('./untitled-font-1.eot');
    src: url('./untitled-font-1.eot?#iefix') format('embedded-opentype'), url('./untitled-font-1.woff') format('woff'), url('./untitled-font-1.ttf') format('truetype'), url('./untitled-font-1.svg#untitled-font-1') format('svg');
    font-weight: normal;
    font-style: normal;
}

[data-icon]:before {
    font-family: 'untitled-font-1' !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
    font-family: 'untitled-font-1' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-efc-account-workspace:before {
    content: '\31';
}
.icon-efc-account-workspace-o:before {
    content: '\32';
}
.icon-efc-permissions:before {
    content: '\61';
}
.icon-efc-cabinet:before {
    content: '\63';
}
.icon-efc-cabinet-o:before {
    content: '\64';
}
.icon-efc-drawer:before {
    content: '\65';
}
.icon-efc-drawer-add:before {
    content: '\66';
}
.icon-efc-drawer-o:before {
    content: '\67';
}
.icon-efc-file-add:before {
    content: '\68';
}
.icon-efc-folder-add:before {
    content: '\69';
}
.icon-efc-my-files:before {
    content: '\6a';
}
.icon-efc-my-shares:before {
    content: '\6b';
}
.icon-efc-outlook:before {
    content: '\6c';
}
.icon-efc-share:before {
    content: '\6e';
}
.icon-efc-share-o:before {
    content: '\6f';
}
.icon-efc-cabinet-add:before {
    content: '\70';
}
.icon-efc-permission-key-01:before {
    content: '\71';
}
.icon-efc-guest-user:before {
    content: '\72';
}
.icon-efc-logo:before {
    content: '\73';
}
.icon-efc-gdrive:before {
    content: '\74';
}
.icon-efc-onedrive:before {
    content: '\75';
}
.icon-efc-gdrive-o:before {
    content: '\76';
}
.icon-efc-onedrive-o:before {
    content: '\77';
}
.icon-efc-logo-o:before {
    content: '\78';
}
.icon-efc-office:before {
    content: '\79';
}
