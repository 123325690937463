.permissions-grant-access-tooltip .tooltip {
    opacity: 1;
}

.permissions-grant-access-tooltip .tooltip-inner {
    max-width: 332px;
    background-color: #321E4B;
    padding: 20px 15px;
    text-align: left;
}

.permissions-grant-access-tooltip .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
    border-bottom-color: #321E4B;
}
