.shareable-link {
    .link {
        flex-grow: 1;
        color: var(--atl-main-text)
    }

    .input {
        height: 40px;
        width: 100%;
        padding-left: 8px;
        padding-right: 100px;
        border: none;
        border-radius: 5px;
        background-color: #f5f5f5;
    }

    .icon {
        height: 20px;
        padding: 0 4px;
    }

    .copy-button {
        position:absolute;
        left: calc(100% - 135px);
        width: auto;
        cursor: pointer;
        color: var(--atl-primary);
        display: flex;
        align-items: center;
        height: 40px;
        padding-right: 10px;
    }
    .copy-button:hover {
        cursor: pointer;
        color: var(--atl-primary-dark-hover);
        .icon {
            fill: var(--atl-primary-dark-hover);
            color: var(--atl-primary-dark-hover);
        }
    }
}