body {
    --openVerticalNavWidth: 16rem;
}

.vertical-nav {
    position: fixed;
    top: 0;
    height: calc(100vh);
    z-index: 20;
}

@media screen and (max-width: 575px)  {
    .iframe {
        width: 100%;
        height: 100%;
    }
}

@media screen and (min-width: 576px) {
    .iframe {
        width: 100%;
        height: calc(100% - 1rem);
    }
}

.layout {
    background-color: var(--page-container-bgcolor);
}

.pages {
    --pages-left: var(--openVerticalNavWidth);
    --pages-top: var(--nav-height);
    position: absolute;
    overflow:hidden;
    top: var(--pages-top);
    left: var(--pages-left);
    height: calc(100vh - var(--pages-top));
    width: calc(100% - var(--pages-left));
    transition: var(--animation-duration);
    overflow: hidden;
}

.pages.left {
    --pages-left: 3.8rem;
}

.pages.no-left-nav {
    --pages-left: 0rem;
    height: calc(100vh - (var(--nav-height)*2));
}

.pages.no-bottom-nav-no-left-nav {
    --pages-left: 0rem;
    height: calc(100vh - var(--pages-top));
}

@supports (height: 100dvh) {
    .pages {
        --pages-left: var(--openVerticalNavWidth);
        --pages-top: var(--nav-height);
        position: absolute;
        overflow:hidden;
        top: var(--pages-top);
        left: var(--pages-left);
        height: calc(100dvh - var(--pages-top));
        width: calc(100% - var(--pages-left));
        transition: var(--animation-duration);
        overflow: hidden;
    }

    .pages.no-left-nav {
        --pages-left: 0rem;
        height: calc(100dvh - (var(--nav-height)*2));
    }

    .pages.no-bottom-nav-no-left-nav {
        --pages-left: 0rem;
        height: calc(100dvh - var(--pages-top));
    }
}

.page-container {
    height: 100%;
}

.extra-small-view-bottom-nav-padding {
    padding: 0;
}

.layout-row {
    height: 100%;
    margin-right: 0px;
}

.layout-row-width-with-sidesheet {
    width: 100%;
}

.layout-row-width-with-sidesheet-closed > div:first-child {
    padding-right: 4px;
}

.layout-row-width-with-sidesheet-open > div:first-child {
    padding-right: 0px;
}

.sidesheet-col {
    height: calc(100% - 1rem);
    padding-left: 0px !important;
}

.grey-background {
    background-color: #F6F6F6;
}