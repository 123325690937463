.mobile-modal-spacing {
    margin-top: calc(100vh/2 - 12rem);
}

.account-guest-welcome-modal-greeting {
    display: inline-block;
    color: var(--grey-dark-100, #434343);
    font-size: 14px;
    font-family: Nunito;
    font-style: normal;
    line-height: 20px;
    margin-bottom: 0.75rem;
}