
@media screen and (max-width: 575.98px)  {
    .account-guest-welcome-image {
        max-width: 200px;
        max-height: 80px;
        border-radius: 5px;
    }

    .account-guest-welcome-message {
        padding-top: 1rem;
        text-align: center;
        width: auto;
    }

    .account-guest-welcome-image-spacing {
        flex: 0 0 auto;
        text-align: center;
    }

    .text-weight {
        font-weight: 700;
        display: block;
    }

    .account-guest-welcome-card-spacing {
        width: 98%;
        text-align: center;
        margin: 0 0 2em 0.3em;
    }
}

@media screen and (min-width: 576px) {
    .account-guest-welcome-image {
        max-width: 200px;
        max-height: 80px;
        border-radius: 5px;
    }

    .account-guest-welcome-message {
        align-self: center;
        width: auto;
        text-align: left;
    }

    .account-guest-welcome-image-spacing {
        flex: 0 0 auto;
        width: auto;
        text-align: center;
        align-self: center;
        margin-left: auto;
        margin-right: auto;
    }

    .text-weight {
        font-weight: 500;
    }

    .account-guest-welcome-card-spacing {
        width: 97%;
        text-align: center;
        margin: 0 0 2em 0.55em;
    }
}

@media screen and (min-width: 992px){
    .account-guest-welcome-card-spacing {
        width: 98%;
        text-align: center;
        margin: 0 0 2em 0.55em;
    }
}

.account-guest-welcome-custom-border {
    border: 1px;
    border-style: solid;
    border-color: #ECECEC;
}

.reduced-card-padding {
    padding: 8px 0 8px 0;
}

.account-guest-welcome-image {
    background-color: #D9D9D9;
}
