.sidesheet-onboarding-modal-lg-1 {
    background-image: url('../../../assets/images/sidesheetOnboardingModalLg1.svg');
}

.sidesheet-onboarding-modal-lg-2 {
    background-image: url('../../../assets/images/sidesheetOnboardingModalLg2.svg');
}

.sidesheet-onboarding-modal-lg-3 {
    background-image: url('../../../assets/images/sidesheetOnboardingModalLg3.svg');
}

.sidesheet-onboarding-modal-lg-body {
    height: 492px;
}