.role-selector-wrapper {
    max-width: 300px;
    padding-right: 1.1875rem;
}

.role-selector-border-no-error {
    border-color: #B4B4B4 !important;
}

.role-selector-error-message {
    color: #CC1531;
}

.role-selector-input {
    border-radius: .1875rem 0 0 .1875rem;
    padding: .67rem .75rem .67rem .75rem;
    border: 1px solid;
    font-size: 14px;
}

.role-selector-input-error {
    border-left-color: #CC1531;
    border-top-color: #CC1531;
    border-right-color: #B4B4B4;
    border-bottom-color: #CC1531;
}

.role-selector-button {
    border-radius: 0 .1875rem .1875rem 0;
    border: 1px solid;
    color: #292929;
    padding: .6rem .75rem .4rem .75rem;
    height: fit-content;
}

.role-selector-button-error {
    border-left-color: #B4B4B4;
    border-top-color: #CC1531;
    border-right-color: #CC1531;
    border-bottom-color: #CC1531;
}

.role-selector-results-container {
    overflow-y: auto;
    overflow-x: hidden;
    height: 200px;
    width: 100%;
    background: white;
    border: 1px solid #ECECEC;
    border-radius: 3px;
    box-shadow: 0px 5px 10px #ECECEC;
    padding: 10px;
}

.role-selector-container {
    display: grid;
    margin-bottom: 20px;
}

.role-selector-container-row {
    grid-row-start: 1;
    grid-column-start: 1;
    background-color: #fff;
    z-index: 1;
}

.role-selector-chip-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.role-selector-search-row {
    max-width: 300px;
}