.sidesheet-onboarding-modal {
    background-size: cover;
}

.sidesheet-onboarding-modal-header {
    border-bottom: none;
}

.sidesheet-onboarding-modal-footer {
    border-top: none;
    justify-content: space-between;
}