.role-type-icon {
    margin-right: auto;
    margin-left: auto;
    margin-top: 0px !important;
    color: #292929;
}

.role-type-custom-icon {
    height: 1em;
    font-size: 20px;
}

.role-type-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #696969 !important;
}

.role-type-row-container { }

.role-type-row-container :hover {
    background: #F8F3FC;
    cursor: pointer;
}

.role-type-row {
    height: 40px;
}

.role-type-text-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 5px;
}

.role-type-dropdown-icon {
    margin-left: auto !important;
    color: #696969;
}

.role-type-dropdown-icon-right {
    padding-right: 3px;
}

.role-selector-no-matching-results {
    color: #696969 !important;
}