.role-row-container {
    height: 35px;
}

.role-row-expanded {
    height: 60px !important;
}

.role-row-container :hover {
    background: #F8F3FC;
    cursor: pointer;
}

.role-already-added {
    color: #B4B4B4 !important;
}

.role-row {
    height: 35px;
}

.role-search-default-selection {
    background: #F8F3FC;
}

.role-icon {
    margin: 0 auto;
    color: #292929;
}

.role-custom-icon {
    height: 1em;
    font-size: 20px;
}

.role-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.role-text-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 5px;
    color: #292929 !important;
}
