.file-password-modal-title {
    font-weight: 700;
    font-size: 28px;
    color: #292929;
}

.file-password-error {
    color: #CC1531;
}

.file-password-remove-button {
    width: 248.72px !important;
}

.file-password-submit-button {
    width: 70.77px !important;
}