.welcome-preview-overlay {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 295px;
    width: 100%;
    background-color: rgba(128, 128, 128, 0.5);
}

.welcome-preview-container {
    min-height: 295px;
}

.welcome-preview-container * {
    cursor: default;
}

.modal-preview {
    background-color: #270c3f;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.welcome-modal {
    min-width: 90%;
    max-width: 500px;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    padding: 1rem;
}

.welcome-preview-container .message-container {
    border: 1px solid #e5e5e5;
    padding: 0.5rem 0.5rem;
    border-radius: 5px;
    margin: 1rem 0;
    display: flex;
    align-items: center;
    min-height: 39px;
}

.welcome-preview-container .logo-container {
    min-width: 113px;
    min-height: 43px;
    height: 43px;
    max-width: 113px;
    max-height: 43px;
    margin-right: 0.75rem;
}

.center-logo {
    display: flex !important;
    justify-content: center !important;
    border: none !important;
}

.center-logo .logo-container {
    min-width: 281px;
    min-height: 98px;
    height: 98px;
    max-width: 281px;
    max-height: 98px;
}

.logo {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: #d9d9d9;
}

.welcome-modal .welcome-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.message-container .message {
    white-space: normal;
    font-size: 1.5em;
}

.welcome-preview-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;
}

.welcome-preview-container .button {
    border: 1px solid #7514bd;
    border-radius: 0.375rem;
    background-color: #7514bd;
    color: #fff;
    text-align: center;
    padding: 0.375rem 0.75rem;
    width: 65px;
}

.home-preview {
    background-color: #f6f6f6;
}

.home-preview > .header {
    width: 100%;
    height: 50px;
    background-color: #fff;
    padding: 0.5rem 1rem;
    border: 1px solid #f6f6f6;
    border-radius: 3px;
}

.search {
    display: flex;
    align-items: center;
    padding: 0.3rem 1rem;
    background-color: #f6f6f6;
    max-width: 500px;
}

.home-preview .body {
    padding-left: 1rem;
}

.home-preview .body > .message-container {
    padding: 0.5rem 1rem;
    background-color: #fff;
    border: none;
    border-radius: 5px 0 0 5px;
}

.home-preview .to-do-items {
    background-color: #fff;
    padding: 0.3rem 1.5em;
    border-radius: 5px 0 0 5px;
    margin-top: 1rem;
}

.home-preview .to-do-items > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 0.5rem 0;
}

.to-do-items .to-do-item {
    background-color: #ececec;
    border-radius: 5px;
    height: 30px;
    width: 100%;
    margin-bottom: 1rem;
}

.home-preview .body > .empty-box {
    height: 25px;
    width: 100%;
    background-color: #fff;
}

@media (max-width: 475px) {
    .message-container {
        flex-direction: column;
    }

    .message-container .message {
        text-align: center;
    }

    .logo-container {
        margin-bottom: 0.5rem;
    }
}
