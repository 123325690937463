.library-modal-body {
    height: calc(100vh - var(--nav-height)* 2.5);
    overflow: hidden;
    background-color: #F6F6F6;
}

.library-modal-body-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;
}

.library-modal-body-card-left {
    border-radius: 5px;
    background-color: white;
    width: 69%;
    padding: 10px;
    margin: 0px 5px;
    box-shadow: 0px 4px 10px 0px #00000026;
}

.library-modal-body-card-right {
    border-radius: 5px;
    background-color: white;
    width: 29%;
    padding: 10px;
    margin: 0px 5px;
    box-shadow: 0px 4px 10px 0px #00000026;
    min-width: 275px;
}

.library-modal-footer {
    justify-content: flex-end;
    box-shadow: 0px -5px 15px 0px #00000026;
    z-index: 10;
}

.library-modal-horizontal-divider {
    padding: 0;
    background-color: #ECECEC;
    height: 2px;
    margin-top: 5px;
}

.library-modal-col {
    padding-bottom: calc(var(--nav-height) - 1rem);
}

.library-modal-col-scroll {
    overflow-y: auto;
    overflow-x: hidden;
}

.library-details-col {
    height: 85%
}

.library-skeleton-text {
    display: flex;
    align-items: center;
    height: 50%;
    justify-content: center;
}

.md-group-details-description-text {
    color: #696969 !important;
}

.md-group-details-description-text-emphasis {
    color: #292929;
}