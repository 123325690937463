.pro-tip {
    color: #696969;
    text-align: left;
}

.pro-tip-body {
    color: #696969;
    text-align: left;
    padding-left: 25.2px;
}

.pro-tip-code-font {
    font-family: "Roboto Mono";
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid #ECECEC;
    border-radius: 3px;
    padding: 2px;
    gap: 10px;
}
