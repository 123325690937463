.step-counter {
    position: absolute;
    bottom: 15px;
}

.tour-container {
    padding: 24px 20px 30px 20px;
    min-height: 90px;
}

.tour-container > div {
    margin: 0;
    justify-content: flex-end;
}

.tour-container > div > button {
    margin: 0;
    bottom: -23px;
    position: relative;
}

.tour-container > button {
    top: 10px;
    right: 10px;
}