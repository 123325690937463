@media screen and (min-width: 768px) {
    .page-not-found-content-area {
        width:auto;
        height: 90%;
        background-position: right 25% top 80%;
        background-repeat: no-repeat;
        padding-left: 1em;
        margin-top: 3em;
    }

    .page-not-found-revver-icon {
        height: 4em;
        margin-bottom: 1.75em;
    } 

    .page-not-found-title {
        width: auto;
        font-weight: 900;
        font-size: 4em;
        margin-bottom: 0.5em;
    }

    .page-not-found-description {
        width: 60%;
        font-size: 14px;
        font-weight: 700;
    }
}

@media screen and (min-width: 576px) and (max-width: 767.9px) {
    .page-not-found-content-area {
        width:auto;
        height: 80%;
        background-position: right 15% top 80%;
        background-repeat: no-repeat;
        padding-left: 1em;
        margin-top: 3em;
    }

    .page-not-found-revver-icon {
        height: 4em;
        margin-bottom: 1.75em;
    } 

    .page-not-found-title {
        width: auto;
        font-weight: 900;
        font-size: 3em;
        margin-bottom: 0.5em;
    }

    .page-not-found-description {
        width: 80%;
        font-size: 14px;
        font-weight: 700;
    }
}

@media screen and (max-width: 575.9px) and (orientation: portrait) {
    .page-not-found-content-area {
        width:auto;
        height: 70%;
        background-position: right 15% top 90%;
        background-repeat: no-repeat;
        padding-left: 1em;
        margin-top: 3em;
    }

    .page-not-found-revver-icon {
        height: 3em;
        margin-bottom: 1.75em;
    } 
    
    .page-not-found-title {
        width: auto;
        font-weight: 900;
        font-size: 2em;
        margin-bottom: 0.5em;
    }

    .page-not-found-description {
        width: 85%;
        font-size: 14px;
        font-weight: 700;
    }

}

@media screen and (max-width: 767.9px) and (orientation: landscape) {
    .page-not-found-content-area {
        width:auto;
        height: 60%;
        background-repeat: no-repeat;
        background-position: right 15% top 100%;
        padding-left: 1em;
        margin-top: 5em;
    }

    .page-not-found-revver-icon {
        height: 3em;
        margin: 2em 0 1.75em 0;
    } 
    
    .page-not-found-title {
        width: auto;
        font-weight: 900;
        font-size: 2em;
        margin-bottom: 0.5em;
    }

    .page-not-found-description {
        width: 85%;
        font-size: 14px;
        font-weight: 700;
    }
}

.page-not-found-button {
    margin: 2em 0 1em 0;
}