.survey-reprompt-toast-wrapper {
    position: fixed;
    background-color: #fff;
    bottom: 20px;
    right: 20px;
    width: 500px;
    z-index: 2;
}

.survey-reprompt-toast-header {
    padding: 0.5rem 1.5rem;
    padding-top: 1rem;
    border-bottom: none;
    background-color: #F8F3FC;
}

.survey-reprompt-close-button {
    font-size: .775rem;
    position: relative;
    top: -55px;
    color: #292929;
}

.survey-reprompt-image {
    max-width: 130px;
    margin-right: 20px;
}

.survey-reprompt-header-text {
    font-size: 28px;
    font-weight: 700;
    color: #292929;
}

.survey-reprompt-toast-body {
    padding: 0.75rem 1.5rem;
    font-size: 14px;
    font-weight: 600;
}