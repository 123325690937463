.chip-wrapper {
    height: 35px;
    display: block;
    background-color: #F8F3FC;
    border: 1px solid #7514BD;
    border-radius: 5px;
    margin: 2px;
    padding: 2px 8px 2px 2px;
}

.chip-icon {
    color: #292929 !important;
    margin: .25rem;
    position: relative;
    top: calc(50% - 10px);
}

.chip-close-icon {
    width: 14px;
    margin-left: .3rem !important;
    margin-right: 0px !important;
    cursor: pointer;
}