@keyframes grow {
    0% {
        opacity: 0;
        height: 0%;
        transform: translateY(-5%);
    }
    50% {
        opacity: 0.4;
        height: 5%;
    }
    100% {
        opacity: 1;
        height: 78%;
        transform: translateY(0);
    }
}

@keyframes shrink {
    0% {
        height: 78%;
        transform: translateY(0);
    }
    50% {
        opacity: 0.3;
        height: 5%;
    }
    100% {
        opacity: 0;
        height: 0%;
        transform: translateY(-5%);
    }
}

.grow-animation {
    animation-name: grow;
    animation-duration: 0.4s;
    transition-timing-function: ease-out;
    transition: 0.4s;
}

.shrink-animation {
    animation-name: shrink;
    animation-duration: 0.4s;
    transition-timing-function: ease-in;
    transition: 0.4s;
}

.mt-2r {
    margin-top: 2rem;
}

.mt-75r {
    margin-top: .75rem;
}

.mb-75r {
    margin-bottom: .75rem;
}

.pointer label, .pointer input {
    cursor: pointer;
}

.nested-line {
    border-left: 1px solid #7514BD;
    height: 78%;
    margin-left: 7px;
}