.profile-items-divider-col {
    width: 1%;
    max-width: 1%;
}

.profile-items-content-col {
    width: 99%;
    padding-top: 10px;
}

.profile-items-divider {
    height: 100%;
    width: 1px;
    background-color: #7514BD;
}