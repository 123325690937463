.check-mark-complete {
    color: green;
}

.airplane {
    position: absolute;
    right: -9px;
    top: 0;
}

.modal-pdf-link {
    font-size: 14px;
}

.empty-padding-left {
    padding-left: 0px;
}

.empty-padding-right {
    padding-right: 0px;
}

.icon-wrapper {
    width: 41px;
    display: inline-block;
    height: 41px;
}

.icon-edit {
    margin: auto !important;
    padding-left: 7px;
    color: #7514BD;
    cursor: pointer;
    font-size: 18px;
}

.icon-warning {
    margin: auto !important;
}

.icon-circle {
    font-size: 41px;
}

.icon-check {
    font-size: 28px;
    padding-left: 0.48rem;
    padding-top: 0.48rem;
}

.icon-stacked {
    position: absolute;
}

.icon-unfinished {
    color: #D9D9D9;
}

.icon-finished {
    color: #1E9B3E;
}

.domain-label { 
    display: block;
}

.input-name {
    max-width: 408px;
}

.input-domain {
    max-width: 190px;
    margin-right: 4px;
    display: inline-block;
}

.remind-button {
    margin: 10px;
}

.smaller-view-buttons {
    font-size: .8rem;
    padding: 0.1rem 0.3rem;
}

.modal-pointer {
    cursor: pointer;
}

.vertical-line-wrapper {
    margin-left: 7px;
    height: 100%;
}

.vertical-line {
    background-color: #D9D9D9;
    width: 2px;
    margin: 0 auto;
    height: calc(100% - 40px);
    transform: translateY(-3px);
}

.company-logo-wrapper {
    position: relative;
    max-width: fit-content;
}

.company-logo {
    max-width: 364px;
    max-height: 145px;
    border: 10px solid #FFFFFF;
    outline: 2px solid #ECECEC;
}

.company-logo-cancel-icon {
    position: absolute;
    right: 0;
    top: -10px;
    cursor: pointer;
    color: #8E8E8E;
    background: white;
}

.company-logo-size-text {
    cursor: pointer;
    color: #7514BD;
    text-align: center;
    font-weight: bold;
}

.company-logo-button {
    width: auto;
    margin-left: 9px;
}

.temp-hide-modal {
    display: none;
}