.jump-in-col {
    align-content: space-around;
    display: flex;
    flex-wrap: wrap;
}

.view-guide-divider {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
    border-top: 1px solid #80808029;
    border-bottom: 1px solid #80808029;
    padding: 10px 0;
}

.download-button {
    color: #8e8e8e;
    text-decoration: none;
    font-size: .875rem;
    font-weight: 600;
}

.new-experience-mobile-modal-spacing {
    margin-top: calc(100vh/2 - 16rem);
}